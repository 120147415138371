import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use( VueRouter )

const routes = [
	// 首页
	{
		path: '/',
		name: 'Home',
		component: () => import( '@/views/home/home.vue' )
	},

	// 登录
	{
		path: '/login',
		name: 'login',
		component: () => import( '@/views/login.vue' )
	},

	// 文章管理
	{
		path: '/articleList',
		name: 'articleList',
		component: () => import( '@/views/article/articleList.vue' )
	},

	// 轮播图管理
	{
		path: '/swiperList',
		name: 'swiperList',
		component: () => import( '@/views/swiper/swiperList.vue' )
	},

	// 医生管理
	{
		path: '/doctorList',
		name: 'doctorList',
		component: () => import( '@/views/doctor/doctorList.vue' )
	},

	// 任务管理
	{
		path: '/taskList',
		name: 'taskList',
		component: () => import( '@/views/task/taskList.vue' )
	},

	// 管理员设置
	{
		path: '/adminList',
		name: 'adminList',
		component: () => import( '@/views/admin/adminList.vue' )
	},

	// 小程序管理
	{
		path: '/setting',
		name: 'setting',
		component: () => import( '@/views/setting/setting.vue' )
	},
	
	// 公众号管理
	{
		path: '/publicSetting',
		name: 'publicSetting',
		component: () => import( '@/views/setting/publicSetting.vue' )
	},
	
	// 银屑病 任务分类
	{
		path: '/yxbTaskType',
		name: 'yxbTaskType',
		component: () => import( '@/views/yxb/yxbTaskType.vue' )
	},

	// 银屑病 任务管理
	{
		path: '/yxbTaskList',
		name: 'yxbTaskList',
		component: () => import( '@/views/yxb/yxbTaskList.vue' )
	},
	
	// 痒疹 任务分类
	{
		path: '/yzTaskType',
		name: 'yzTaskType',
		component: () => import( '@/views/yz/yzTaskType.vue' )
	},
	
	// 痒疹 任务管理
	{
		path: '/yzTaskList',
		name: 'yzTaskList',
		component: () => import( '@/views/yz/yzTaskList.vue' )
	},
	
	// 公告管理
	{
		path: '/noticeList',
		name: 'noticeList',
		component: () => import( '@/views/notice/noticeList.vue' )
	},
	
	// 宣教文章
	{
		path: '/studyList',
		name: 'studyList',
		component: () => import( '@/views/study/studyList.vue' )
	},
	
	// 宣教文章类型
	{
		path: '/studyType',
		name: 'studyType',
		component: () => import( '@/views/study/studyType.vue' )
	},
]

const router = new VueRouter( {
	mode: 'history',
	base: process.env.BASE_URL,
	routes
} )

export default router
