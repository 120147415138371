<template>
	<div id="app">
		<el-container v-if="loginStatus === '1'">
			<el-aside width="200px" class="menuBox">
				<el-menu :uniqueOpened="true" :default-active="menuIndex" class="el-menu-vertical-demo"
					background-color="#545c64" text-color="#fff" active-text-color="#409EFF" router>
					<el-menu-item index="1" @click="menuChange" route="/" v-if="type == 1 || type == 2">
						<i class="el-icon-s-platform"></i>
						<template #title>
							首页
						</template>
					</el-menu-item>
					<el-menu-item index="2" @click="menuChange" route="/articleList" v-if="type == 2">
						<i class="el-icon-s-management"></i>
						<template #title>
							文章管理
						</template>
					</el-menu-item>
					<el-submenu index="11" v-if="type == 1 || type == 2">
						<template slot="title">
							<i class="el-icon-s-management"></i>
							宣教文章
						</template>
						<el-menu-item index="11-1" @click="menuChange" route="/studyList">文章管理</el-menu-item>
						<el-menu-item index="11-2" @click="menuChange" route="/studyType" v-if="type == 2">文章分类</el-menu-item>
					</el-submenu>
					<el-menu-item index="10" @click="menuChange" route="/noticeList" v-if="type == 2">
						<i class="el-icon-s-order"></i>
						<template #title>
							公告管理
						</template>
					</el-menu-item>
					<el-menu-item index="7" @click="menuChange" route="/taskList" v-if="type == 2">
						<i class="el-icon-s-order"></i>
						<template #title>
							任务管理
						</template>
					</el-menu-item>
					<el-submenu index="8" v-if="type == 2">
						<template slot="title">
							<i class="el-icon-s-grid"></i>
							银屑病专区
						</template>
						<el-menu-item index="8-1" @click="menuChange" route="/yxbTaskList">问卷管理</el-menu-item>
						<el-menu-item index="8-2" @click="menuChange" route="/yxbTaskType">问卷分类</el-menu-item>
					</el-submenu>
					<el-submenu index="9" v-if="type == 2">
						<template slot="title">
							<i class="el-icon-s-grid"></i>
							痒疹专区
						</template>
						<el-menu-item index="9-1" @click="menuChange" route="/yzTaskList">问卷管理</el-menu-item>
						<el-menu-item index="9-2" @click="menuChange" route="/yzTaskType">问卷分类</el-menu-item>
					</el-submenu>
					<el-menu-item index="3" @click="menuChange" route="/swiperList" v-if="type == 2">
						<i class="el-icon-picture"></i>
						<template #title>
							轮播图管理
						</template>
					</el-menu-item>
					<el-menu-item index="4" @click="menuChange" route="/doctorList" v-if="type == 2">
						<i class="el-icon-s-custom"></i>
						<template #title>
							医生管理
						</template>
					</el-menu-item>
					<el-menu-item index="5" @click="menuChange" route="/adminList" v-if="type == 2">
						<i class="el-icon-user-solid"></i>
						<template #title>
							管理员管理
						</template>
					</el-menu-item>
					<el-menu-item index="6" @click="menuChange" route="/setting" v-if="type == 2">
						<i class="el-icon-menu"></i>
						<template #title>
							小程序管理
						</template>
					</el-menu-item>
					<el-menu-item index="11" @click="menuChange" route="/publicSetting" v-if="type == 2">
						<i class="el-icon-menu"></i>
						<template #title>
							公众号管理
						</template>
					</el-menu-item>
				</el-menu>
			</el-aside>

			<el-container>
				<el-header>
					<div class="headerBox">
						<div class="title">健康管理系统</div>
						<el-dropdown class="menu" @command="quit">
							<div>{{ name }} ，您好</div>
							<template #dropdown>
								<el-dropdown-menu>
									<el-dropdown-item style="color:#F56C6C">退出登录</el-dropdown-item>
								</el-dropdown-menu>
							</template>
						</el-dropdown>
					</div>
				</el-header>

				<el-main style="height: 70vh;">
					<router-view />
				</el-main>
			</el-container>
		</el-container>

		<div v-if="loginStatus != '1'">
			<router-view />
		</div>
	</div>
</template>

<script>
	var storage = window.localStorage;
	export default {
		name: 'app',
		data() {
			return {
				loginStatus: null,
				menuIndex: '',
				name: '',
				type: ''
			};
		},

		watch: {
			$route() {
				this.loginStatus = storage.loginStatus;
				this.name = storage.name;
				this.type = storage.type;
				if (this.loginStatus != 1) {
					console.log(1);
					this.$router.push('/login');
				}
				if (storage.menuIndex) {
					this.menuIndex = storage.menuIndex;
				}
			}
		},

		created() {
			this.loginStatus = storage.loginStatus;
			this.name = storage.name;
			this.type = storage.type;

			if (this.loginStatus != '1') {
				console.log(1);
				this.$router.push('/login');
			}
		},

		methods: {
			menuChange(e) {
				storage.menuIndex = e.index;
			},

			quit() {
				console.log(2);
				this.$confirm('确认退出登录?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						storage.clear();
						this.$router.push('/login');
					})
					.catch(() => {});
			}
		}
	};
</script>

<style lang="less">
	body {
		padding: 0 !important;
		margin: 0 !important;
		background: #fafafa;
		// font-family: '微软雅黑', 'Roboto Mono', 'Source Sans Pro', 'Monaco', 'courier', 'monospace' !important;
	}

	#app {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.menuBox {
		height: 100vh;
		background: rgb(84, 92, 100);
		box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	}

	.el-header {
		padding: 0 !important;
	}

	.headerBox {
		height: 56px;
		line-height: 56px;
		padding: 0 20px;
		background: #fff;
		box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
		display: flex;
		justify-content: space-between;

		.title {
			font-size: 26px;
			color: #409eff;
			font-weight: 700;
		}

		.menu {
			display: flex;
			align-items: center;
			color: #409eff;
			cursor: pointer;
		}
	}

	.el-main {
		padding: 20px;
	}

	.labelBox {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.title {
			font-size: 30px;
			margin-bottom: 20px;
		}
	}
</style>
